<template>
    <section class="afp-prod" :class="{ 'col-tow': aftermarketHeadList.length === 2 }">
        <div class="prod-head">
            <div v-for="item in aftermarketHeadList" :key="item" class="prod-col">{{ item }}</div>
        </div>
        <div class="prod-body">
            <div v-for="item in aftermarketProdList" :key="item.commodity_details_id" class="prod-row">
                <div class="prod-col">
                    <div class="afp-prod">
                        <a href="javascript:void(0);" class="afp-img">
                            <img :src="item.imgthumbnail" :alt="item.title">
                        </a>
                        <p class="afp-txt">
                            <span class="afp-txt-til">{{ item.title }}</span>
                            <span class="txt-grey"> 出荷时间： {{ parseInt(item.publishtype) === 1 ? '未定' : `${item.publishyear}年${item.publishtime}` }}</span>
                            <span class="txt-grey"> 定价: {{ parseInt(item.moneytype) === 1 ?'未定价':`${item.rmb}元` }} </span>
                        </p>
                    </div>
                </div>
                <!-- <div v-if="item.count" class="prod-col">{{ item.count }}</div> -->
                <div v-if="orderMoney&&orderMoney.dingjin" class="prod-col">{{ orderMoney.dingjin }}</div>
                <div v-if="orderMoney&&orderMoney.weikuan" class="prod-col">{{ orderMoney.weikuan }}</div>
                <div v-if="orderMoney" class="prod-col">{{ orderMoney.allmoney || orderMoney.money }}</div>
            </div>
        </div>
    </section>
</template>
<script>
export default {
    name: 'AftermarketProdList',
    props: {
        aftermarketHeadList: {
            type: Array,
            default: null,
            required: true
        },
        orderMoney: {
            type: Object,
            default: null
        },
        aftermarketProdList: {
            type: Array,
            default: null,
            required: true
        }
    },
    data() {
        return {
            reason: ['库存不足', '限购已满', '已截单', '已下架'] // 失效原因
        }
    }
}
</script>
<style lang="scss" scoped>
.prod-head {
    position: relative;
}
.prod-row {
    bafp-bottom: 1px solid $grayest;
    &:last-child {
        bafp-bottom: none;
    }
}
.prod-col {
    @include inlineBlock();
    vertical-align: top;
    width: 160px;
    padding: 20px 0;
    &:first-child {
        width: 680px;
    }
}
.prod-head {
    .prod-col {
        padding: 0 20px;
        font-size: 14px;
        &:first-child {
            padding-left: 0;
        }
    }
}
.afp-prod {
    position: relative;
}
.afp-img {
    @include inlineBlock();
    margin-right: 20px;
    width: 82px;
    height: 82px;
    border: 1px solid $gray;
    > img {
        display: block;
        width: 100%;
        height: 100%;
    }
}
.afp-txt {
    @include inlineBlock();
    vertical-align: top;
    width: 500px;
    .afp-txt-til{
        font-size: 13px;color: #000;
    }
    span{
        display: block;line-height: 1.2; padding-bottom: 20px;
        &:last-child{
            padding-bottom: 0;
        }
    }
}
.afp-txt-item {
    display: block;
    color: $light;
    &:first-child {
        margin-bottom: 10px;
        height: 55px;
        overflow: hidden;
        color: $black3;
    }
}
.col-tow {
    .prod-head,
    .prod-body {
        .prod-col:nth-child(1) {
            width: calc(100% - 180px);
        }
    }
}
.afp-invalid {
    .prod-body {
        color: $light;
    }
    .afp-txt-item {
        color: $light;
    }
}
</style>
