<template>
    <div class="main aftermarket">
        <simple-header :user-info="userInfo" />
        <top />
        <product-list v-if="Object.keys(informationDetail).length > 0" class="aftermarket-item" :aftermarket-head-list="validOrderHeadList" :aftermarket-prod-list="[informationDetail]" :order-money="informationDetail" />
        <div class="aftermarket-item">
            <div class="afc-head">
                <h3 class="afc-tit">
                    <span class="afc-tit-txt">订单截图</span>
                    <small class="afc-tit-subtit">请提交您在购买渠道的购物截图，图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件</small>
                </h3>
            </div>
            <div class="afc-body">
                <el-upload
                    class="upload-my"
                    accept="image/png,image/jpeg,image/gif"
                    :class="{ 'upload-disabled': orderimgUploadDisabled }"
                    action="/aftermarket/imgUpload"
                    list-type="picture-card"
                    :data="uploadData"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="orderimgRemove"
                    :on-change="orderimgChange"
                    :on-success="orderimgHandleSuccess"
                    :before-upload="beforeLimitImgSizeUpload"
                >
                    <i class="el-icon-plus" />
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </div>
        </div>
        <div class="aftermarket-item">
            <div class="afc-head">
                <h3 class="afc-tit">
                    <span class="afc-tit-txt">购买渠道</span>
                </h3>
            </div>
            <div class="afc-body form-box">
                <el-input
                    v-model="form.orderchannel"
                    placeholder="请填写购买渠道"
                    clearable
                />
            </div>
            <div class="afc-head">
                <h3 class="afc-tit">
                    <span class="afc-tit-txt">订单编号</span>
                    <small class="afc-tit-subtit">同一个订单编号只能申请一次售后，请妥善保管好自己的订单编号</small>
                </h3>
            </div>
            <div class="afc-body form-box">
                <el-input
                    v-model="form.orderid"
                    placeholder="请填写订单编号"
                    clearable
                />
            </div>
        </div>
        <div class="aftermarket-item afc-item">
            <div class="afc-head">
                <h3 class="afc-tit">问题描述</h3>
            </div>
            <div class="afc-body form-box">
                <el-input v-model="form.description" type="textarea" rows="6" maxlength="200" show-word-limit />
            </div>
            <div class="afc-head">
                <h3 class="afc-tit">
                    <span class="afc-tit-txt">问题描述</span>
                    <small class="afc-tit-subtit">一次最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件</small>
                </h3>
            </div>
            <div class="afc-body">
                <el-upload
                    class="upload-my"
                    accept="image/png,image/jpeg,image/gif"
                    :class="{ 'upload-disabled': uploadDisabled }"
                    action="/aftermarket/imgUpload"
                    list-type="picture-card"
                    :data="uploadData"
                    :on-preview="handlePictureCardPreview"
                    :on-remove="handleRemove"
                    :on-change="handleChange"
                    :on-success="handleSuccess"
                    :before-upload="beforeLimitImgSizeUpload"
                >
                    <i class="el-icon-plus" />
                </el-upload>
                <el-dialog :visible.sync="dialogVisible" :close-on-click-modal="false">
                    <img width="100%" :src="dialogImageUrl" alt="">
                </el-dialog>
            </div>
        </div>
        <div class="btn-wrapper">
            <a href="javascript:;" class="btn-primary btn-round" :class="{ disabled:isDisabled }" @click="aftermarketAdd">提交</a>
        </div>
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import top from '../top.vue'
import productList from '../productList.vue'
import { aftermarketAdd, getInformationDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
export default {
    name: 'AftermarketCommit',
    components: {
        simpleHeader,
        simpleFooter,
        top,
        productList
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            form: {
                informationid: '',
                orderchannel: '',
                orderid: '',
                orderimg: '',
                description: '',
                img: []
            },
            imgFileList: [],
            uploadData: { frames: 1, files: null },
            dialogImageUrl: '',
            dialogVisible: false,
            orderimgUploadDisabled: false,
            uploadDisabled: false,
            informationDetail: {},
            commodity: [],
            orderMoney: {},
            validOrderHeadList: ['产品']
        }
    },
    computed: {
        isDisabled() {
            return this.form.orderimg === '' || this.form.orderid === '' || this.form.orderchannel === '' || (this.form.description === '' && this.imgFileList.length === 0)
        }
    },
    created() {
        this.getAccount()
    },
    methods: {
        async getInformationDetail(params) {
            const res = await getInformationDetail(params)
            // console.log('informationDetail res', res)
            if (res.code === 1001) {
                this.informationDetail = res.detail
            }
            // if (res.code === 1001) {
            //     this.orderDetail = Object.assign({}, res.list)
            //     this.commodity = res.list.commodity
            //     this.orderMoney = Object.assign({}, res.list.ordermoney)
            // }
        },
        // 3售后提交,必传: orderid，commodity_details_id，type（1维修 2补件 3退货 4换货）
        // 可传: desc（描述），img（图片 多个用丨号分隔）
        async aftermarketAdd() {
            if (this.isDisabled) return
            console.log('imgFileList', this.imgFileList)
            this.form.img = this.imgFileList.join('|')
            console.log('form', this.form.img)
            const res = await aftermarketAdd(this.form)
            console.log('aftermarketAdd res', res)
            if (res.code === 1001) {
                this.$router.push('/my/aftermarket-success')
            }
        },
        beforeLimitImgSizeUpload(file) {
            const isLt5M = file.size / 1024 / 1024 < 5
            if (!isLt5M) {
                this.$message.error('图片大小不能超过5MB!')
            }
            return isLt5M
        },
        handleRemove(file, fileList) {
            console.log(file, fileList)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            } else {
                this.uploadDisabled = false
            }
            // this.form.imgFiles = fileList.map(item => item.raw)
            const idx = this.imgFileList.findIndex(item => item === file.response.detail.img)
            this.imgFileList.splice(idx, 1)
            // console.log('imgFiles', this.form.img)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleChange(file, fileList) {
            console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.form.imgFiles = fileList.map(item => item.raw)
            console.log('imgFiles', this.form.img)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            }
        },
        handleSuccess(response) {
            console.log('response', response)
            this.imgFileList.push(response.detail.img)
        },
        orderimgRemove(file, fileList) {
            // console.log(file, fileList)
            if (fileList.length >= 1) {
                this.orderimgUploadDisabled = true
            } else {
                this.orderimgUploadDisabled = false
            }
            this.form.orderimg = '' // 清空订单图片数据
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('orderimg', this.form.orderimg)
        },
        orderimgChange(file, fileList) {
            // console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('orderimg', this.form.orderimg)
            if (fileList.length >= 1) {
                this.orderimgUploadDisabled = true
            }
        },
        orderimgHandleSuccess(response) {
            console.log('response', response)
            this.form.orderimg = response.detail.img
        }
    },
    beforeRouteEnter(to, from, next) {
        // console.log('from, to', from, to)
        next(vm => {
            const queryData = vm.$route.query
            // console.log(queryData)
            if (queryData && queryData.informationid) {
                vm.form.informationid = queryData.informationid // id
                vm.getInformationDetail(queryData)
            }
            // this.getInformationDetail(queryData)
            // console.log('queryData', queryData)
            // if (queryData && queryData.orderid) {
            //     // vm.form.orderid = queryData.orderid
            //     // vm.form.commodity_details_id = queryData.commodity_details_id
            //     vm.form = Object.assign({}, vm.form, queryData)
            //     vm.myOrderDetail(vm.form.orderid)
            // }
        })
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.aftermarket-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
    .afc-body{
        &:last-child{
            margin-bottom: 5px;
        }
    }
}
.btn-wrapper {
    width: 1200px;
    margin: 10px auto 20px;
    text-align: right;
    .btn-primary {
        width: 200px;
    }
}
.afc-tit {
    margin-bottom: 10px;
    font-weight: normal;
}
.afc-tit-subtit {
    padding: 0 10px;
    color: $light;
}
.afc-body {
    margin-bottom: 20px;
    &.form-box{
        width: 500px;
    }
}
.btn-primary{
    &.disabled{
        color: #999;
        background: #ccc;
        border-color: #ccc;
        cursor: no-drop;
    }
}

</style>
