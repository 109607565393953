<template>
    <div class="main aftermarket-success">
        <simple-header />
        <top :sub-title="false" />
        <section class="afs-item aftermarket-item afs-result mt20">
            <h3 class="afs-title">售后申请提交成功！</h3>
            <p class="afs-txt">客服将在2~3个工作日内进行售后审核，请耐心等候</p>
            <p class="afs-txt afs-tips">{{ secondTime }}秒后自动返回网站首页</p>
            <router-link to="/my/aftermarket/list" class="btn-primary btn-round">查看售后订单</router-link>
            <router-link to="/" class="btn-primary btn-round">返回首页</router-link>
        </section>
        <simple-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import top from '../top.vue'
export default {
    name: 'AftermarketSuccess',
    components: {
        simpleHeader,
        simpleFooter,
        top
    },
    data() {
        return {
            secondTime: 15,
            timer: null
        }
    },
    created() {
        this.countDown()
    },
    methods: {
        countDown() {
            const self = this
            this.timer = window.setInterval(() => {
                if (self.secondTime > 0) {
                    self.secondTime--
                } else {
                    window.clearInterval(self.timer)
                    self.$router.push('/')
                }
            }, 1000)
        }
    },
    beforeRouteLeave(to, from, next) {
        window.clearInterval(this.timer)
        next()
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.afs-title {
    margin-bottom: 10px;
    font-size: 18px;
}
.afs-txt {
    margin-bottom: 10px;
    color: $light;
}
.afs-tips {
    color: $red;
}
.footer {
    position: fixed;
    width: 100%;
    bottom: 0;
    left: 0;
}
</style>
