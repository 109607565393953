<template>
    <div class="main aftermarket">
        <simple-header :user-info="userInfo" />
        <top :sub-title="false" :step="aftermarketStep" />
        <afd-info :aftermarket-detail="aftermarketDetail" />
        <product-list v-if="Object.keys(aftermarketDetail).length > 0" class="aftermarket-item" :aftermarket-head-list="validOrderHeadList" :aftermarket-prod-list="[aftermarketDetail]" :order-money="aftermarketDetail" />
        <div v-if="aftermarketDetail.buttontype === 1" class="aftermarket-item afc-item">
            <div class="afc-head">
                <h3 class="afc-tit">售后发回地址</h3>
            </div>
            <div class="afc-body">
                <div class="kefu-info">
                    <div class="thumb"><img :src="aftermarketDetail.kefuheadimgurl" :alt="aftermarketDetail.kefuname"></div>
                    <div class="infor">
                        <span class="name"> {{ aftermarketDetail.kefuname }} </span>
                        <span class="date txt-grey"> {{ aftermarketDetail.kefuaddtime }} </span>
                    </div>
                </div>
                <div class="kefu-address"> 地址：{{ aftermarketDetail.kefuaddress }} </div>
            </div>
        </div>
        <div v-if="isBtnShow" class="aftermarket-item afc-item">
            <div class="afc-head">
                <h3 class="afc-tit">操作</h3>
            </div>
            <div class="afc-body">
                <a v-if="isAddDescBtnShow" href="javascript:void(0);" class="btn-primary btn-round" @click="addDesDialogVisible=true">+ 新增描述</a>
                <a v-if="aftermarketDetail.status === '6' && aftermarketDetail.buttontype === 1" href="javascript:void(0);" class="btn-primary btn-round" @click="addSetDialogVisible=true">+ 添加收发信息</a>
                <a v-if="aftermarketDetail.status === '6' && aftermarketDetail.buttontype === 2" href="javascript:void(0);" class="btn-primary btn-round" @click="addResDialogVisible=true">+ 填写收货信息</a>
                <a v-if="isBtnShow" href="javascript:void(0);" class="btn-primary btn-round" @click="handleCancel">取消售后申请</a>
            </div>
        </div>
        <history :init-history-list="historyList" />
        <add-des-dialog :visible.sync="addDesDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" />
        <add-set-dialog :visible.sync="addSetDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" />
        <add-res-dialog :visible.sync="addResDialogVisible" :aftermarketid="aftermarketDetail.id" @getData="getAftermarketDetail" />
        <page-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import simpleFooter from '@c/simpleFooter.vue'
import top from '../top.vue'
import productList from '../productList.vue'
import afdInfo from './afdInfo.vue'
import history from './history.vue'
import addDesDialog from './addDesDialog.vue'
import addSetDialog from './addSetDialog.vue'
import addResDialog from './addResDialog.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getAftermarketDetail, addAftermarketDetail } from '@api/aftermarket'
import PageFooter from '@c/pageFooter.vue'
export default {
    name: 'AftermarketDetail',
    components: {
        simpleHeader,
        // eslint-disable-next-line vue/no-unused-components
        simpleFooter,
        top,
        afdInfo,
        productList,
        history,
        addDesDialog,
        addSetDialog,
        addResDialog,
        PageFooter
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            validOrderHeadList: ['产品'],
            addDesDialogVisible: false,
            addSetDialogVisible: false,
            addResDialogVisible: false,
            aftermarketorderid: '',
            aftermarketDetail: {},
            historyList: [],
            aftermarketStep: 0
        }
    },
    computed: {
        isAddDescBtnShow() {
            const __status = this.aftermarketDetail.status
            return __status === '1' || __status === '2' || __status === '3'
        },
        isBtnShow() {
            const __status = this.aftermarketDetail.status
            return __status === '1' || __status === '2' || __status === '3' || __status === '6'
        }
    },
    created() {
        this.getAccount()
    },
    methods: {
        async getAftermarketDetail() {
            const res = await getAftermarketDetail({ aftermarketorderid: this.aftermarketorderid })
            console.log('getAftermarketDetail res', res)
            const resDetail = res.detail
            if (resDetail && resDetail.aftermarket) {
                this.aftermarketDetail = Object.assign({}, this.aftermarketDetail, resDetail.aftermarket)
                this.historyList = resDetail.detail
                // console.log('historyList', this.historyList, this.historyList.find(item => ['7', '8'].includes(item.type)))
                if (this.historyList[0]) {
                    this.historyList.forEach(item => {
                        if (item.logistics) {
                            this.$set(item.logistics, 'showPopover', false)
                        }
                    })
                }
                // status 4或者5 售后完成，三个步骤全部亮
                this.aftermarketStep = (parseInt(this.aftermarketDetail.status) === 4 || parseInt(this.aftermarketDetail.status) === 5) ? 2 : 1
                // if (this.aftermarketDetail.time2) {
                //     this.aftermarketStep = 1
                // }
                // if (this.aftermarketDetail.time3) {
                //     this.aftermarketStep = 2
                // }
            }
            console.log('aftermarketDetail', this.aftermarketDetail)
        },
        handleCancel() {
            this.$confirm('确定要取消售后申请吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
            }).then(async() => {
                const res = await addAftermarketDetail({ aftermarketid: this.aftermarketDetail.id, type: 4 })
                console.log('cancel res', res)
                const resDetail = res.detail
                if (resDetail && resDetail.status === 1) {
                    this.$notify({ title: '提示', type: resDetail.status === 1 ? 'success' : 'error', message: resDetail.status === 1 ? '操作成功' : '操作失败', position: 'bottom-right' })
                }
                if (resDetail.status === 1) {
                    this.$router.push('/my/aftermarket/list')
                }
            }).catch(() => {})
        }
    },
    beforeRouteEnter(to, from, next) {
        console.log('from, to', from, to)
        next(vm => {
            console.log('vm.$route.query', vm.$route.query)
            vm.aftermarketorderid = vm.$route.query['aftermarketorderid']
            if (vm.aftermarketorderid) {
                vm.$set(vm.aftermarketDetail, 'aftermarketorderid', vm.aftermarketorderid)
                vm.getAftermarketDetail()
            }
        })
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.aftermarket-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.btn-wrapper {
    width: 1200px;
    margin: 10px auto 20px;
    text-align: right;
    .btn-primary {
        width: 200px;
    }
}
.afc-tit {
    margin-bottom: 10px;
    font-weight: normal;
}
.kefu-info{
    overflow: hidden;margin-bottom:10px;margin-top:20px;
    .thumb{
        width: 36px;height: 36px;overflow: hidden; background: #ff0022;position: relative;border-radius:3px;float:left;
        img{
            width: 100%;height: 100%;
            position: absolute;top: 50%;left: 50%;
            transform: translate(-50%,-50%);
        }
    }
    .infor{
        padding-left: 16px;
        float: left;
        .name{
            display: block;padding-bottom: 2px;padding-top:2px;
        }
    }
}

</style>
