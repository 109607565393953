<template>
    <div class="simple-logo aftermarket-logo">
        <div class="simple-logo-box">
            <img class="simple-logo-img" src="@a/img/login-logo.png" width="128" height="50" alt="kamisoul">
            <h3 class="simple-title">售后申请</h3>
            <h4 v-if="subTitle" class="aftermarket-title">填写并提交售后信息</h4>
        </div>
        <section class="step-wrapper">
            <page-step class="step-number" :step-data="stepData" />
        </section>
    </div>
</template>
<script>
import pageStep from '@c/pageStep.vue'
export default {
    name: 'OrderLogo',
    components: {
        pageStep
    },
    props: {
        subTitle: {
            type: Boolean,
            default: true
        },
        step: {
            type: Number,
            default: 0
        }
    },
    data() {
        return {
            stepData: [
                { up: '', down: '1.提交售后申请', active: true },
                { up: '', down: '2.客服审核', active: false },
                { up: '', down: '3.售后完成', active: false }
            ]
        }
    },
    watch: {
        step: {
            handler: function(val) {
                // console.log('val', val)
                this.stepData.forEach((item, index) => {
                    item.active = index <= val
                })
            },
            immediate: true
        }
    }
}
</script>
<style lang="scss" scoped>
.simple-logo {
    min-height: 135px;
}
.step-wrapper {
    position: absolute;
    top: 20px;
    left: 50%;
    width: 610px;
    margin-left: -20px;
}
.aftermarket-title {
    padding: 40px 0 20px;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
}
</style>
