<template>
    <div>
        <el-dialog class="dialog-my" :before-close="() => { $emit('update:visible', false) }" title="填写收货信息" :close-on-click-modal="false" :visible.sync="visible" width="460px">
            <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                <el-form-item prop="address" label="寄回区域" :rules="[{ required: true, message: '请选择所在区域', trigger: 'change' }]">
                    <province-city-area @setFullAddress="getFullAddress" />
                </el-form-item>
                <el-form-item label="详细地址" :rules="[{ required: true, message: '请输入详细地址', trigger: 'blur' }]">
                    <el-input v-model="addressDesc" type="textarea" placeholder="请输入详细地址" maxlength="200" show-word-limit clearable @change="ruleForm.address+=$event" @keyup.enter.native="onSubmit" />
                </el-form-item>
                <el-form-item prop="username" label="收件人" :rules="[{ required: true, message: '请输入收件人', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.username" :maxlength="11" clearable @keyup.enter.native="onSubmit" />
                </el-form-item>
                <el-form-item prop="mobile" label="联系方式" :rules="[{ required: true, message: '请输入联系方式', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.mobile" :maxlength="11" clearable @keyup.enter.native="onSubmit" />
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">保存</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import provinceCityArea from '@c/provinceCityArea'
import { addAftermarketDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
export default {
    name: 'AddResDialog',
    components: {
        provinceCityArea
    },
    mixins: [mixin],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        aftermarketid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ruleForm: {
                type: 3,
                address: '',
                username: '',
                mobile: ''
            },
            addressDesc: ''
        }
    },
    watch: {
        visible(val) {
            console.log('ruleForm', this.ruleForm)
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = ''
                }
                this.ruleForm.type = 3
            }
        }
    },
    methods: {
        getFullAddress(addressObj) {
            console.log('full address', addressObj)
            this.ruleForm.address = addressObj.province.name + addressObj.city.name + addressObj.area.name
        },
        async onSubmit() {
            console.log('form', this.ruleForm)
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    const res = await addAftermarketDetail({ aftermarketid: this.aftermarketid, ...this.ruleForm })
                    console.log('addAftermarketDetail res', res)
                    const resDetail = res.detail
                    const resStatus = resDetail.status
                    this.$notify({ title: '提示', type: resStatus === 1 ? 'success' : 'warning', message: resStatus === 1 ? '操作成功' : resDetail.msg, position: 'bottom-right' })
                    this.$emit('getData')
                    this.$emit('update:visible', false)
                }
            })
        }
    }
}
</script>

<style></style>
