<template>
    <div class="main aftermarket">
        <simple-header :user-info="userInfo" />
        <top />
        <!-- <div class="aft-wrapper">
            <h4 class="aftermarket-title">填写并提交售后信息</h4>
        </div> -->
        <div class="wrapper-box">
            <h3>请点击本次想要申请售后的产品</h3>
            <info-list :info-list="informationList" :list-type="2" @handlerCommit="aftermarketCommit" />
            <el-pagination
                class="page-custom"
                background
                layout="prev, pager, next"
                :page-size="pageSize"
                :total="totalCount"
                :hide-on-single-page="true"
                @current-change="handleCurrentChange"
            />
        </div>
        <el-dialog
            class="dialog-my"
            title="提示"
            :visible.sync="dialogVisible"
            width="460px"
        >
            <div class="tips-box">
                <h4>您的本月售后申请次数异常！</h4>
                <p>您申请售后次数异常，系统已启动风控系统，请下月再来申请。</p>
            </div>
            <span slot="footer" class="dialog-footer">
                <el-button type="primary" @click="dialogVisible = false">确 定</el-button>
            </span>
        </el-dialog>
        <page-footer />
    </div>
</template>
<script>
import simpleHeader from '@c/simpleHeader.vue'
import top from '../top.vue'
import PageFooter from '@c/pageFooter.vue'
import mixin from '@c/mixins'
import authMixin from '@c/mixins/auth'
import { getInformationList, checkAftermarket } from '@api/aftermarket'
import InfoList from '@c/infoList.vue'
export default {
    name: 'AftermarketInformation',
    components: {
        top,
        simpleHeader,
        PageFooter,
        InfoList
    },
    mixins: [mixin, authMixin],
    data() {
        return {
            dialogVisible: false,
            totalCount: 0, // 总条目数
            currentPage: 1,
            pageSize: 20, // 每页4条
            informationList: [],
            aftermarketStep: 0
        }
    },
    created() {
        this.getAccount()
        this.getInformationList()
    },
    methods: {
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
            this.currentPage = val
            this.getInformationList()
        },
        aftermarketCommit(informationid) {
            console.log('跳转页面', informationid)
            // router.push({ name: 'user', params: { userId: 123 } })
            // router.push({ path: 'register', query: { plan: 'private' }})
            // 检测售后次数 status 1次数正常 2次数异常
            this.checkAftermarket().then(status => {
                console.log(status)
                if (status === 1) {
                    this.$router.push({ path: '/my/aftermarket-commit', query: { informationid }})
                } else {
                    // console.log('您申请售后次数异常，系统已启动风控系统，请下月再来申请。')
                    this.dialogVisible = true
                }
            })
            // this.$router.push({ path: '/my/aftermarket-commit', query: { informationid }})
        },
        async checkAftermarket() {
            const result = await checkAftermarket()
            return new Promise((resolve, reject) => {
                if (result.code === 1001) {
                    resolve(result.detail.status)
                } else {
                    reject('请求出错，请重新发起申请')
                }
            })
        },
        async getInformationList() {
            const res = await getInformationList({ page: this.currentPage })
            console.log('informationList res', res.list)
            if (res.code === 1001) {
                this.informationList = res.list.list
                this.pageSize = res.list.pagesize
                this.totalCount = res.list.count
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-logo {
    position: relative;
    height: auto;
    padding-bottom: 0;
}
.aftermarket-item {
    position: relative;
    width: 1200px;
    min-height: 120px;
    padding: 20px;
    margin: 10px auto;
    border: none;
    background-color: $white;
    &.mt20 {
        margin-top: 20px;
    }
}
.wrapper-box{
    width: 1200px;min-height: 600px;padding-top: 40px;padding-bottom: 50px; margin: 0 auto;
    h3{
        font-size: 18px;padding-bottom: 30px;color: #ff0000;
    }
}
.btn-wrapper {
    width: 1200px;
    margin: 10px auto 20px;
    text-align: right;
    .btn-primary {
        width: 200px;
    }
}
.afc-tit {
    margin-bottom: 10px;
    font-weight: normal;
}
.afc-body {
    padding: 0 20px;
}
.tips-box{
    padding: 30px 0;color:#999;
    h4{
        font-size: 18px;font-weight: normal;color: #333;padding-bottom: 10px;
    }
}
</style>
