import Http from './http'
// 2021/10/19新增 申请售后列表 接口 http://km.renmnet.com/aftermarket/information
export function getInformationList(params) {
    return Http({
        url: '/aftermarket/information',
        method: 'post',
        params
    })
}
// 2021/10/19新增 申请售后列表 接口 http://km.renmnet.com/aftermarket/informationDetail
export function getInformationDetail(params) {
    return Http({
        url: '/aftermarket/informationDetail',
        method: 'post',
        params
    })
}
// 2021/10/19新增 申请售后列表 接口 http://km.renmnet.com/aftermarket/checkAftermarket
export function checkAftermarket() {
    return Http({
        url: 'aftermarket/checkAftermarket',
        method: 'post'
    })
}
// 2021/10/19新增 售后快递查询 接口 http://km.renmnet.com/aftermarket/getKuaidiInfo?fareorder=73156717860929
export function getAftermarketKuaidiInfo(fareorder) {
    return Http({
        url: `aftermarket/getKuaidiInfo?fareorder=${fareorder}`,
        method: 'get'
    })
}

// 1售后申请列表
export function getList(params) {
    return Http({
        url: 'aftermarket/index',
        method: 'get',
        params
    })
}
// 2售后上传图片,必传: frames=1 files图片文件
export function imgUpload(data) {
    return Http({
        url: 'aftermarket/imgUpload',
        method: 'post',
        data
    })
}

// 3售后提交,必传: orderid，commodity_details_id，type（1维修 2补件 3退货 4换货）
// 可传: desc（描述），img（图片 多个用丨号分隔）
export function aftermarketAdd(params) {
    return Http({
        url: '/aftermarket/aftermarketAdd',
        method: 'post',
        params
    })
}

// 4售后详情,必传: aftermarketorderid
export function getAftermarketDetail(params) {
    return Http({
        url: 'aftermarket/getAftermarketDetail',
        method: 'post',
        params
    })
}

// 5快递单号查询快递公司,必传: fareorder
export function getcomcode(params) {
    return Http({
        url: 'aftermarket/getcomcode',
        method: 'get',
        params
    })
}

// 6售后记录
export function aftermarketList(params) {
    return Http({
        url: '/aftermarket/aftermarketList',
        method: 'post',
        params
    })
}

// 7售后详情操作，必传aftermarketid，type(1新增描述 2添加收发信息 3填写收货信息 4取消售后申请)
// 可传: desc(描述)，img（多个丨号分隔） fareorder（快递单号 ）comcode（快递公司code） address，username，mobile
export function addAftermarketDetail(params) {
    return Http({
        url: 'aftermarket/addAftermarketDetail',
        method: 'post',
        params
    })
}
