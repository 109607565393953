<template>
    <div>
        <el-dialog class="dialog-my" :before-close="() => { $emit('update:visible', false) }" title="新增描述" :close-on-click-modal="false" :visible.sync="visible" width="460px">
            <el-form ref="ruleForm" :model="ruleForm" class="simple-form">
                <el-form-item prop="desc" label="问题描述" :rules="[{ required: true, message: '请输入问题描述', trigger: 'blur' }]">
                    <el-input v-model="ruleForm.desc" show-word-limit maxlength="200" type="textarea" clearable @keyup.native="ruleForm.desc=ruleForm.desc.replace(/\s+/g, '')" @keyup.enter.native="onSubmit" />
                    <!-- </el-form-item>
                <el-form-item> -->
                    <p class="file-tips">一次最多可上传5张图片，每张图片大小不超过5M，支持bmp,gif,jpg,png,jpeg格式文件</p>
                    <el-upload
                        ref="upload"
                        class="upload-my"
                        accept="image/png,image/jpeg,image/gif"
                        :class="{ 'upload-disabled': uploadDisabled }"
                        action="/aftermarket/imgUpload"
                        list-type="picture-card"
                        :data="uploadData"
                        :on-preview="handlePictureCardPreview"
                        :on-remove="handleRemove"
                        :on-change="handleChange"
                        :on-success="handleSuccess"
                        :on-error="handleError"
                    >
                        <i class="el-icon-plus" />
                    </el-upload>
                    <el-dialog :visible.sync="dialogVisible" append-to-body :close-on-click-modal="false">
                        <img width="100%" :src="dialogImageUrl" alt="">
                    </el-dialog>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <a href="javascript:;" class="btn-primary btn-round" @click="onSubmit">提交</a>
            </div>
        </el-dialog>
    </div>
</template>

<script>
import { addAftermarketDetail } from '@api/aftermarket'
import mixin from '@c/mixins'
export default {
    name: 'AddDesDialog',
    mixins: [mixin],
    props: {
        visible: {
            type: Boolean,
            default: false
        },
        aftermarketid: {
            type: String,
            default: ''
        }
    },
    data() {
        return {
            ruleForm: {
                desc: '',
                img: '',
                type: 1
            },
            dialogImageUrl: '',
            dialogVisible: false,
            uploadDisabled: false,
            imgFileList: [],
            uploadData: { frames: 1, files: null }
        }
    },
    watch: {
        visible(val) {
            if (val) {
                this.$refs.ruleForm && this.$refs.ruleForm.resetFields()
                for (const key in this.ruleForm) {
                    this.ruleForm[key] = ''
                }
                this.ruleForm['type'] = 1
                this.uploadDisabled = false;
            } else {
                this.uploadData.files = null
                this.imgFileList = []
                this.$refs.upload && this.$refs.upload.clearFiles()
            }
        }
    },
    methods: {
        handleRemove(file, fileList) {
            console.log(file, fileList)
            this.imgFileList = this.imgFileList.filter(item => item !== file.response.detail.img)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            } else {
                this.uploadDisabled = false
            }
            // this.form.imgFiles = fileList.map(item => item.raw)
            // console.log('imgFiles', this.form.imgFiles)
            console.log(this.imgFileList.length)
        },
        handlePictureCardPreview(file) {
            this.dialogImageUrl = file.url
            this.dialogVisible = true
        },
        handleChange(file, fileList) {
            console.log(file, fileList)
            this.uploadData.files = file.raw
            // this.ruleForm.imgFiles = fileList.map(item => item.raw)
            console.log('imgFiles', this.ruleForm.imgFiles)
            if (fileList.length >= 5) {
                this.uploadDisabled = true
            }
        },
        handleSuccess(response, file, fileList) {
            console.log('response', response)
            this.imgFileList.push(response.detail.img)
        },
        handleError(err, file, fileList) {
            console.log('err', err)
            this.$notify({ title: '提示', type: 'error', message: err || '上传失败', position: 'bottom-right' })
        },
        async onSubmit() {
            this.$refs['ruleForm'].validate(async valid => {
                if (valid) {
                    this.ruleForm.img = this.imgFileList.join('|')
                    const res = await addAftermarketDetail({ aftermarketid: this.aftermarketid, ...this.ruleForm })
                    console.log('addAftermarketDetail res', res)
                    const resDetail = res.detail
                    const resStatus = resDetail ? resDetail.status : 1
                    this.$notify({ title: '提示', type: resStatus === 1 ? 'success' : 'warning', message: resStatus === 1 ? '操作成功' : resDetail.msg, position: 'bottom-right' })
                    this.$emit('getData')
                    this.$emit('update:visible', false)
                }
            })
        }
    }
}
</script>
<style lang="scss" scoped>
.file-tips {
    margin-bottom: 15px;
    font-size: 12px;
    line-height: 1.4;
    color: $light;
}
.upload-my {
    margin-bottom: 10px;
}
</style>
