<template>
    <div class="my-order">
        <div class="my-head">
            <h3 class="my-title">
                <span>售后申请</span>
                <!-- <small class="sub-title">剩余付款时限：24小时25分32秒</small> -->
            </h3>
        </div>
        <div class="my-body">
            <div class="aftermarket-til">
                <router-link class="btn-primary btn-small btn-rounder order-btn" to="/my/aftermarket/information">我要申请售后</router-link>
            </div>
            <afterMarkedList :type="3" :product-list="aftermarkedtList" />
        </div>
        <div class="pagination-box">
            <el-pagination class="page-custom" background layout="prev, pager, next" :page-size="20" :total="total" :hide-on-single-page="true" @current-change="handleCurrentChange" />
        </div>
    </div>
</template>
<script>
import afterMarkedList from '../afterMarkedList'
import { aftermarketList } from '@api/aftermarket'
// import '../mock'
export default {
    name: 'MyAftermarket',
    components: {
        afterMarkedList
    },
    data() {
        return {
            // activeName: '1', // 1.申请退换货;
            // unAftermarketList: [
            //     // { orderid: '1' },
            //     // { orderid: '2' },
            //     // { orderid: '3' },
            //     // { orderid: '4' },
            //     // { orderid: '5' },
            //     // { orderid: '6' },
            //     // { orderid: '7' },
            //     // { orderid: '8' }
            // ],
            aftermarkedtList: [],
            total: 0
        }
    },
    watch: {
        '$route'(to, from) {
            if (to.params.type) {
                this.activeName = to.params.type
            }
        }
    },
    created() {
        // this.getList()
        this.aftermarketList()
        // const type = this.$router.history.current.params.type
        // console.log('type', type)
        // if (type) {
        //     this.activeName = type
        // }
    },
    methods: {
        // handleSearch(key) {
        //     console.log('key', key)
        //     if(this.activeName === '1') {
        //         this.getList(key);
        //     } else {
        //         this.aftermarketList(key);
        //     }
        // },
        // handleClick(val) {
        //     console.log('val', val)
        //     this.$router.push(`/my/aftermarket/${val.name}`)
        // },
        // 点击页码
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`)
        },
        // async getList(title='') {
        //     const res = await getList({ title })
        //     console.log('aftermarket res', res)
        //     if (res.code === 1001) {
        //         const unAftermarketList = res.list.list
        //         // 由于没有购物车，每单暂时只有一种商品
        //         this.unAftermarketList = []
        //         unAftermarketList.forEach(item => {
        //             console.log('item.commodity[0]', item.commodity[0])
        //             const obj = item
        //             const commodity = item.commodity[0]
        //             if (commodity) {
        //             // 获取未申请售后的数量
        //                 const unAftermarketNum = commodity.buy_num > commodity.aftermarket_num ? commodity.buy_num - commodity.aftermarket_num : 0
        //                 for (let i = 0; i < unAftermarketNum; i++) {
        //                     this.unAftermarketList.push(Object.assign({}, obj, {
        //                         commodity_details_id: commodity.commodity_details_id,
        //                         img: commodity.img,
        //                         title: commodity.title,
        //                         buy_num: commodity.buy_num,
        //                         key: Symbol(),
        //                         money: commodity.money,
        //                         specs: commodity.specs,
        //                         status: commodity.status
        //                     }))
        //                 }
        //             }
        //         })
        //         console.log('unAftermarketList', this.unAftermarketList)
        //         this.total = res.list.total
        //     }
        // },
        async aftermarketList() {
            const res = await aftermarketList()
            console.log('aftermarketList res', res)
            if (res.code === 1001) {
                this.aftermarkedtList = res.list.list
                // this.aftermarkedtList.forEach(item => {
                //     console.log('item.commodity[0]', item.commodity[0])
                //     // item = Object.assign({}, item, item.commodity[0])
                //     this.$set(item, 'buy_num', item.commodity[0].buy_num)
                //     this.$set(item, 'commodity_details_id', item.commodity[0].commodity_details_id)
                //     this.$set(item, 'img', item.commodity[0].img)
                //     this.$set(item, 'title', item.commodity[0].title)
                // })
                this.total = res.list.total
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-til{
    padding: 10px 0 20px 0;
}
.my-title {
    margin-bottom: 20px;
    font-weight: normal;
    font-size: 18px;
    .sub-title {
        padding: 0 5px;
        font-size: 12px;
        color: $light;
    }
}
.my-body {
    min-height: 1000px;
    position: relative;
    padding: 10px 20px;
    background-color: $white;
}
</style>
