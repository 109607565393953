<template>
    <section class="afd-list">
        <div class="afd-list-in">
            <h4 class="aftermarket-title">售后详情</h4>
            <!-- <dl class="afd-list-item">
                <dt>服务类型</dt>
                <dd>{{ [ '维修', '补件', '退货', '换货' ][ +aftermarketDetail.type - 1 ] }}</dd>
            </dl> -->
            <dl class="afd-list-item">
                <dt>售后单号</dt>
                <dd>{{ aftermarketDetail.aftermarketorderid }}</dd>
            </dl>
            <dl class="afd-list-item">
                <dt>售后状态</dt>
                <dd>{{ getStatusTxt }}</dd>
            </dl>
            <dl class="afd-list-item">
                <dt>提交时间</dt>
                <dd>{{ aftermarketDetail.addtime }}</dd>
            </dl>
        </div>
    </section>
</template>
<script>
export default {
    name: 'AftermarketInfo',
    props: {
        aftermarketDetail: {
            type: Object,
            default: null
        }
    },
    data() {
        return {
            aftermarketDetailStatus: [
                { id: 1, statusTxt: ' 待审核 ' },
                { id: 2, statusTxt: ' 审核已通过 ' },
                { id: 3, statusTxt: ' 审核未通过 ' },
                { id: 4, statusTxt: ' 售后完成 ' },
                { id: 5, statusTxt: ' 售后已取消 ' },
                { id: 6, statusTxt: ' 资料待填写 ' },
                { id: 7, statusTxt: ' 待售后完成 ' }
            ]
        }
    },
    computed: {
        // eslint-disable-next-line vue/return-in-computed-property
        getStatusTxt() {
            if (this.aftermarketDetail.status) {
                return this.aftermarketDetailStatus.filter((item) => item.id === parseInt(this.aftermarketDetail.status))[0].statusTxt
            }
        }
    }
}
</script>
<style lang="scss" scoped>
.aftermarket-title {
    padding: 20px 0;
    font-weight: normal;
    font-size: 18px;
    text-align: left;
}
.afd-list {
    margin-bottom: 20px;
    border-top: 1px solid $gray;
    background-color: $white;
}
.afd-list-in {
    width: 1200px;
    margin: 0 auto;
    text-align: left;
}
.afd-list-item {
    @include inlineBlock();
    width: 300px;
    margin-bottom: 20px;
    color: $light;
    dt {
        margin-bottom: 10px;
        font-size: 14px;
    }
    dd {
        font-size: 18px;
    }
    &:last-child {
        width: 200px;
        margin-left: 400px;
    }
}
</style>
