<template>
    <div class="aftermarket-item afc-item">
        <div class="afc-head">
            <h3 class="afc-tit">售后历史</h3>
        </div>
        <div class="afc-body">
            <!-- type(1新增描述 2添加收发信息 3填写收货信息 4取消售后申请) -->
            <section v-for="item in historyList" :key="item.id" class="history-item">
                <div class="history-head">
                    <img class="history-head-img" :src="item.headimgurl" :alt="item.username">
                    <p class="hisotry-head-name">{{ item.username }}</p>
                    <p class="hisotry-head-date">{{ item.addtime }}</p>
                </div>
                <div class="history-body">
                    <template v-if="item.type==='1'">
                        <p v-if="item.desc" class="history-body-item">{{ item.desc }}</p>
                        <p v-if="item.img && item.img.length > 0" class="history-body-img">
                            <img v-for="imgSrc in item.img" :key="imgSrc" :src="imgSrc || require('@a/img/history-img.png')" alt="">
                        </p>
                    </template>
                    <div v-if="item.type==='2'" class="history-body-item">
                        <p>添加收发信息：<br><br>发货物流信息：{{ `${item.takeinfo.usersfareorder}，` + `${item.takeinfo.usersfarename}` }}
                            <el-popover
                                v-if="item.takeinfo.usersfareorder"
                                v-model="item.takeinfo.showPopover"
                                title="物流信息"
                                width="400"
                                trigger="manual"
                            >
                                <i class="el-icon-close pop-close" title="关闭" @click="$set(item.takeinfo, 'showPopover', false)" />
                                <div class="pop-content">
                                    <p v-for="(delivery, index) in deliveryList" :key="index">{{ delivery.time + ',' + delivery.context }}</p>
                                    <p v-if="!deliveryList[0]">暂无相关物流信息</p>
                                </div>
                                <span slot="reference" class="detail-con"><a class="detail-link" href="javascript:;" @click.prevent="getAftermarketKuaidiInfo(item.takeinfo)">查看</a></span>
                            <!-- <a slot="reference" href="javascript:;" @click.prevent="getKuaidiInfo(item)">查看物流信息</a> -->
                            </el-popover>
                        </p>
                        <p>收货信息：{{ `${item.takeinfo.usersname}，` + `${item.takeinfo.usersmobile}，${item.takeinfo.usersaddres}` }}</p>
                    </div>
                    <p v-if="item.type==='3'" class="history-body-item">收货信息：{{ `${item.takeinfo.usersname}，` + `${item.takeinfo.usersmobile}，${item.takeinfo.usersaddres}` }}</p>
                    <p v-if="item.type==='4'" class="history-body-item">取消售后申请</p>
                    <!-- <p v-if="item.type==='5'" class="history-body-item">客服审核</p> -->
                    <p v-if="item.type==='5'" class="history-body-item">{{ item.status==='1'?'审核结果：通过':`审核结果：未通过，理由：${item.examine.reasontype===3?item.examine.reason:reasonTxt[item.examine.reasontype-1]||''}` }}</p>
                    <p v-if="item.type==='6'" class="history-body-item">
                        发货物流信息：{{ (item.logistics.kefufarename ? item.logistics.kefufarename : '') + (item.logistics.kefufareorder ? `，${item.logistics.kefufareorder}` : '') }}
                        <el-popover
                            v-if="item.logistics.kefufareorder"
                            v-model="item.logistics.showPopover"
                            title="物流信息"
                            width="400"
                            trigger="manual"
                        >
                            <i class="el-icon-close pop-close" title="关闭" @click="$set(item.logistics, 'showPopover', false)" />
                            <div class="pop-content">
                                <p v-for="(delivery, index) in deliveryList" :key="index">{{ delivery.time + ',' + delivery.context }}</p>
                                <p v-if="!deliveryList[0]">暂无相关物流信息</p>
                            </div>
                            <span slot="reference" class="detail-con"><a class="detail-link" href="javascript:;" @click.prevent="getAftermarketKuaidiInfo(item.logistics)">查看</a></span>
                            <!-- <a slot="reference" href="javascript:;" @click.prevent="getKuaidiInfo(item)">查看物流信息</a> -->
                        </el-popover>
                    </p>
                    <p v-if="item.type==='7'" class="history-body-item">客服取消售后申请</p>
                    <p v-if="item.type==='8'" class="history-body-item">客服售后完成</p>
                    <p v-if="item.type==='9'" class="history-body-item">客服操作 需寄回</p>
                    <p v-if="item.type==='10'" class="history-body-item">客服操作 无需寄回</p>
                    <p v-if="item.type==='11'" class="history-body-item">{{ item.desc }}</p>
                </div>
            </section>
        </div>
    </div>
</template>
<script>
import { getcomcode, getAftermarketKuaidiInfo } from '@api/aftermarket'
export default {
    name: 'AftermarketHistory',
    props: {
        initHistoryList: {
            type: Array,
            default: null
        }
    },
    data() {
        return {
            reasonTxt: ['不符合售后范围', '退换货商品影响二次销售', '其他'],
            companyList: [], // 快递公司列表
            deliveryList: []
        }
    },
    computed: {
        historyList() {
            return this.initHistoryList
        }
    },
    methods: {
        async getcomcode(fareorder) {
            // 请求查询快递公司，如果查不到则单号有问题
            const res = await getcomcode({ fareorder: fareorder })
            const detail = res.detail
            if (detail && detail.status !== 1) {
                this.$notify({ title: '提示', type: 'warning', message: '快递查询失败，请检查快递单号是否正确！', position: 'bottom-right' })
            } else {
                this.form.code = detail.company[0].code
                this.form.name = detail.company[0].name
            }
            console.log('aftermarketDetail', this.aftermarketDetail)
        },
        // 获取售后物流详细信息
        async getAftermarketKuaidiInfo(item) {
            console.log('item', item)
            if (!item) return
            this.deliveryList = []
            // 先关闭其它
            if (this.historyList[0]) {
                this.historyList.forEach(history => {
                    // type === 6 才会有物流信息
                    if (history.logistics) {
                        this.$set(history.logistics, 'showPopover', false)
                    }
                    if (history.takeinfo) {
                        this.$set(history.takeinfo, 'showPopover', false)
                    }
                })
            }
            this.$set(item, 'showPopover', true)
            const res = await getAftermarketKuaidiInfo(item.kefufareorder || item.usersfareorder)
            // const res = await getAftermarketKuaidiInfo('73156717860929')
            this.deliveryList = res.detail && res.detail.status === 1 ? res.detail.content : []
            console.log('res', res)
        }
    }
}
</script>
<style lang="scss" scoped>
.afc-tit {
    margin-bottom: 10px;
    font-weight: normal;
}
.afc-body {
    margin-bottom: 20px;
}
.history-item {
    padding: 10px 0 0;
    border-bottom: 1px solid $gray;
}
.history-head {
    position: relative;
    padding-left: 46px;
    min-height: 36px;
    line-height: 1.5;
}
.history-head-img {
    width: 36px;
    height: 36px;
    position: absolute;
    top: 0;
    left: 0;
}
.hisotry-head-date {
    color: $light;
}
.history-body {
    padding: 10px 0;
}
.history-body-item {
    line-height: 1.3;
    word-break: break-all;
    a {
        color: $red;
        // &:hover {
        //     text-decoration: underline;
        // }
    }
}
.history-body-img {
    margin-top: 10px;
    > img {
        width: 100px;
        height: 100px;
        margin: 0 10px 10px 0;
    }
}
.pop-close {
    position: absolute;
    top: 5px;
    right: 10px;
    cursor: pointer;
    &:hover {
        color: #409efe;
    }
}
.pop-content {
    padding: 5px;
    position: relative;
    font-size: 12px;
    line-height: 1.5;
    max-height: 180px;
    overflow: auto;
    p {
        margin-bottom: 10px;
    }
}
</style>
